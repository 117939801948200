import { useStyletron, styled } from "baseui";
import { ToolHead } from "../../components/toolHead";
import studio from "../../assets/studio.svg";
import admin from "../../assets/admin.svg";
import insights from "../../assets/insights.svg";
import gsqlshell from "../../assets/gsqlshell.svg";
import graphql from "../../assets/graphql.svg";

const StyledAppList = styled("div", {
  maxWidth: "1200px",
  marginLeft: "auto",
  marginRight: "auto",
  paddingBottom: "20px",
  display: "grid",
  gap: "48px",
  gridTemplateColumns: "repeat(auto-fill, 520px)",
  aligntems: "center",
  justifyItems: "center",
  justifyContent: "center",
  "@media screen and (max-width: 720px)": {
    width: "100%",
  },
});

const StyledAppBox = styled("div", {
  background: "#FFFFFF",
  borderRadius: "10px",
  display: "flex",
  width: "520px",
  height: "150px",
  cursor: "pointer",
  border: "1px solid #D4DADF",
  paddingLeft: "36px",
  paddingRight: "36px",
  alignItems: "center",
  boxSizing: "border-box",
  ":hover": {
    boxShadow: "-2px -2px 30px rgba(25, 118, 210, 0.05), 2px 2px 30px rgba(25, 118, 210, 0.05)",
  },
  position: "relative",
  "@media screen and (max-width: 720px)": {
    width: "80%",
  },
  overflow: "hidden",
});

const StyledAppIMG = styled("div", {
  marginRight: "16px",
  width: "184px",
  height: "96px",
  display: "flex",
  alignItems: "center",
});

const StyledBoxinfo = styled("p", {
  fontFamily: "Urbanist",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "16px",
  color: "#767676",
});

export default function AllApplication() {
  const [css] = useStyletron();
  const username = getUserName();

  function getUserName() {
    const username =
      localStorage.getItem("TigerGraphUIUsername") || "tigergraph";
    try {
      return JSON.parse(username);
    } catch (error) {
      return "tigergraph";
    }
  }
  function navigateApplication(appName: string) {
    window.location.href = `${window.location.origin}/${appName}/`;
  }

  return (
    <>
      <div
        className={css({
          width: "100%",
        })}
      >
        <ToolHead
          appname="TigerGraph Suite"
          appicon="/suittitle.svg"
          innerapp="home"
          user={username}
        />
      </div>
      <p
        className={css({
          paddingTop: "60px",
          paddingBottom: "60px",
          fontFamily: "Urbanist",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "32px",
          lineHeight: "40px",
          textAlign: "center",
          textTransform: "capitalize",
          color: "#294560",
        })}
      >
        What do you want to work on today?
      </p>
      <StyledAppList>
        <StyledAppBox
          onClick={() => {
            navigateApplication("studio");
          }}
        >
          <StyledAppIMG>
            <img
              className={css({
                width: "184px",
                height: "96px",
              })}
              src={studio}
              alt=""
            />
          </StyledAppIMG>
          <StyledBoxinfo>
            Design, develop, map and load your graph database.
          </StyledBoxinfo>
        </StyledAppBox>

        <StyledAppBox
          onClick={() => {
            navigateApplication("insights");
          }}
        >
          <StyledAppIMG>
            <img
              className={css({
                width: "184px",
                height: "96px",
              })}
              src={insights}
              alt=""
            />
          </StyledAppIMG>
          <StyledBoxinfo>
            Build graph powered applications in minutes.
          </StyledBoxinfo>
        </StyledAppBox>

        <StyledAppBox
          onClick={() => {
            navigateApplication("gsql");
          }}
        >
          <StyledAppIMG>
            <img
              className={css({
                width: "184px",
                height: "96px",
              })}
              src={gsqlshell}
              alt=""
            />
          </StyledAppIMG>
          <StyledBoxinfo>Run your GSQL and visualize it.</StyledBoxinfo>
        </StyledAppBox>
        <StyledAppBox
          onClick={() => {
            navigateApplication("admin");
          }}
        >
          <StyledAppIMG>
            <img
              className={css({
                width: "184px",
                height: "96px",
              })}
              src={admin}
              alt=""
            />
          </StyledAppIMG>
          <StyledBoxinfo>Manage your TigerGraph system.</StyledBoxinfo>
        </StyledAppBox>
        <StyledAppBox
          onClick={() => {
            navigateApplication("graphql");
          }}
        >
          <StyledAppIMG>
            <img
              className={css({
                width: "184px",
                height: "96px",
              })}
              src={graphql}
              alt=""
            />
          </StyledAppIMG>
          <StyledBoxinfo>
            Query and update your graph data at ease via GraphQL API.
          </StyledBoxinfo>
        </StyledAppBox>
      </StyledAppList>
    </>
  );
}
