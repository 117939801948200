import React, { useEffect } from "react";
import ComponentProvider from "@tigergraph/app-ui-lib/Provider";
import { SnackbarProvider } from "baseui/snackbar";
import { useStyletron } from "baseui";
import { createTheme } from "@tigergraph/app-ui-lib/Theme";
import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import {
  HashRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Login from "./pages/login";
import AllDashboards from "./pages/allApplication";
import Error404 from "./pages/error404";

import "./App.css";
import version from "./version.json";

axios.defaults.baseURL = sessionStorage.getItem("BASEURL") || window.location.origin;
axios.defaults.withCredentials = true;

console.log("===============================================================");
console.log(" _______                 ______                 __");
console.log(" /_  __(_)___ ____  _____/ ____/________ _____  / /_");
console.log("  / / / / __ `/ _ \\/ ___/ / __/ ___/ __ `/ __ \\/ __ \\");
console.log(" / / / / /_/ /  __/ /  / /_/ / /  / /_/ / /_/ / / / /");
console.log("/_/ /_/\\__, /\\___/_/   \\____/_/   \\__,_/ .___/_/ /_/");
console.log("     /____/                          /_/");
console.log("===============================================================");
console.log(
  "TigerGraph Suite " +
    version.major +
    "." +
    version.minor +
    "." +
    version.build +
    "." +
    version.revision
);
console.log("Powered by TigerGraph");

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      {children}
      <AxiosInterceptor />
    </>
  );
};

function Content() {
  const [css] = useStyletron();

  const authURL = "/api/gsql-server/gsql/simpleauth";
  useQuery(
    "authQuery",
    async () => {
      const response = await axios.get(`${authURL}`);
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data.response && data.response.status === 401) {
          window.location.href = `${window.location.origin}/#/login`;
          return;
        }
        window.localStorage.setItem('TigerGraphUIUsername', JSON.stringify(data.results.name));
      },
    }
  );

  return (
    <SnackbarProvider>
      <HashRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <div
                  className={css({
                    display: "flex",
                  })}
                >
                  {/* <Navigation /> */}
                  <div
                    className={css({
                      // width: 'calc(100vw - 64px)'
                      width: "100%",
                    })}
                  >
                    <Routes>
                      <Route path="/apps" element={<AllDashboards />} />
                      <Route
                        path="/"
                        element={<Navigate to="/apps" replace={true} />}
                      />
                      <Route path="*" element={<Error404 />} />
                    </Routes>
                  </div>
                </div>
              </PrivateRoute>
            }
          />
        </Routes>
      </HashRouter>
    </SnackbarProvider>
  );
}

function AxiosInterceptor() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        let response = error.response as AxiosResponse;
        if (response.status === 401) {
          window.location.href = `${window.location.origin}/#/login`;
          return;
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(responseInterceptor);
    };
  }, [location, navigate]);

  return null;
}

function App() {
  const primitives = {
    primary: "#3557D2", // primary blue
    primaryA: "#1D1D1D", // primary text
    // primaryB: '#686868', // primary grey
    // primary300: '#D2D2D2', // primary light grey
    accent100: "#3557D2", // primary blue
    accent200: "#2641A1", // hover blue
    positive100: "#9AC63D", // success green
    positive200: "#6A950E", // success green text
    positive300: "#DDE8C4", // success green background
    warning100: "rgba(247, 131, 23, 0.8)", // moderate
    warning200: "#F78317", // moderate text
    warning300: "rgba(247, 131, 23, 0.2)", // moderate background
    negative100: "#E56161", // error red
    negative200: "rgba(186, 45, 45, 0.93)", // error red text
    negative300: "#EFCECE", // error red background
  };
  const overrides = {
    colors: {
      backgroundPrimary: "#F9FBFC", // background color
      buttonPrimaryFill: primitives.accent100,
      buttonPrimaryHover: primitives.accent200,
      buttonSecondaryFill: "#fff",
      buttonSecondaryText: primitives.primaryA,
      buttonSecondaryHover: "#fff",
    },
    lighting: {
      shadow400: "0px 2px 20px rgba(0, 0, 0, 0.1)",
    },
    borders: {
      border200: {
        // secondary button border
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#A5A5A5",
      },
    },
    breakpoints: {
      large: 1920,
      medium: 1280,
      small: 720,
    },
    mediaQuery: {
      large: "@media screen and (min-width: 1920px)",
      medium: "@media screen and (min-width: 1280px)",
      small: "@media screen and (min-width: 720px)",
    },
  };
  const theme = createTheme(primitives, overrides);

  return (
    <ComponentProvider theme={theme}>
      <Content />
    </ComponentProvider>
  );
}

export default App;
